export default {
    namespaced: true,

    state: {
        channels:[],
        channel:{},
        playChannel:false
    },
    mutations: {
        setChannels(state, channels) {
            state.channels = channels;
        },
        setChannel(state, channel) {
            state.channel = channel;
        },
        setPlayChannel(state, play) {
            state.playChannel = play;
        },
    },
    actions: {

        setChannels({ commit }, payload) {
          commit('setChannels', payload);
        },
        setChannel({ commit }, payload) {
            commit('setChannel', payload);
          },
          setPlayChannel({ commit }, payload) {
            commit('setPlayChannel', payload);
          },
    },
    getters: {
        getChannels: state => state.channels,
        getChannel: state => state.channel,
        getPlayChannel: state => state.playChannel,
    },
};
