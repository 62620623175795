import { apiClient } from '@/util/resources';

export default {
    namespaced: true,

    state: {
        subscriptions: [],
    },
    mutations: {
        addSubscription(state, payload) {
            state.subscriptions.push(payload);
        },
    },
    actions: {
        subscribeToEvents({ commit, dispatch }, events) {
            if (process.env.VUE_APP_RUN_MERCURE) {
                events.forEach((item) => {
                    dispatch('subscribe', item.id);
                });
            }
        },

        subscribeUser({ dispatch }, user) {
            dispatch('subscribe', user.id);
        },

        subscribe({ commit, rootGetters, dispatch }, topic) {
            if (process.env.VUE_APP_RUN_MERCURE !== 'false') {
                const eventSource = new EventSource('http://localhost:3000/.well-known/mercure?topic=' + encodeURIComponent(topic));
                eventSource.onmessage = event => {
                    let data = JSON.parse(event.data);
                    if (data.event === 'ROOM_CREATED') {
                        dispatch('call/onIncomingCall', data, { root: true });
                    }
                };

                commit('addSubscription', {
                    topic: topic,
                    eventSource: eventSource
                });
            }
        }
    },
    getters: {
        items: state => state.items,
    },
};
