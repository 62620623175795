import Vue from 'vue';
import App from './App.vue';
import router from './router/routes';
import store from './store/store';
import i18n from './locales/i18n';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import '@/util/mixin';
import '@/config/http';
import { ValidationProvider, ValidationObserver, setInteractionMode, extend } from 'vee-validate';
import { required, min, email, numeric, confirmed, regex, image } from 'vee-validate/dist/rules';
import VFacebookLogin from 'vue-facebook-login-component';
import GoogleAuth from 'vue-google-auth';
import VueMoment from 'vue-moment';
import _ from '@/util/lodash';

window._ = _;

import '@/styles/app.scss';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('VFacebookLogin', VFacebookLogin);

setInteractionMode('passive');

extend('required', required);
extend('min', min);
extend('email', email);
extend('numeric', numeric);
extend('confirmed', confirmed);
extend('image', image);
extend('regex', regex);

extend('phone_number', {
  ...regex,
  message: (fieldName, placeholders) => {
    return i18n.t('phone_number_invalid');
  }
});

Vue.config.productionTip = false;
Vue.use(BootstrapVue, BootstrapVueIcons);
Vue.use(VueMoment);
//Vue.use(VueGapi, { clientID: '41049305462-7a7nnmoq0akf3677hjmm9r7l0h22ocu4.apps.googleusercontent.com' });
Vue.use(GoogleAuth, { clientID: '41049305462-7a7nnmoq0akf3677hjmm9r7l0h22ocu4.apps.googleusercontent.com' });
Vue.googleAuth().load();
new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
}).$mount('#app');
