import Vue from 'vue';
// import ValidatorTooltip from '@/components/shared/Util/ValidatorTooltip.vue';

Vue.mixin({
  components: {
    // ValidatorTooltip,
  },
  data() {
    return {
    };
  },
  methods: {
    _size(...args) {
      return _.size(...args);
    },
    _debug(...args) {
      if (process.env.VUE_APP_DEBUG === 'true') {
        console.log(...args);
      }
    },
    _isDebug() {
      return process.env.VUE_APP_DEBUG === 'true';
    }
  }
});
