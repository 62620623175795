import Vue from 'vue';
import Router from 'vue-router';
import { requireAuth, requireNotAuth } from '@/util/auth';

Vue.use(Router);

let router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "portalHome" */ '../pages/home'),
      beforeEnter: requireNotAuth,
      meta: {},
    },
    {
      path: '/activate-registration/:token',
      name: 'regActivate',
      component: () => import(/* webpackChunkName: "portalReg" */ '../pages/activateRegistration/index'),
      beforeEnter: requireNotAuth,
      meta: {},
    },
    {
      path: '/channels/:id/player',
      name: 'channel',
      component: () => import(/* webpackChunkName: "portalChannel" */ '../pages/channels/_id'),
      beforeEnter: requireAuth,
      meta: {},
    },
    {
      path: '/channels',
      name: 'channels',
      component: () => import(/* webpackChunkName: "portalChannels" */ '../pages/channels/index'),
      beforeEnter: requireAuth,
      meta: {},
    },
    {
      path: '/playlist/:id',
      name: 'playlist',
      component: () => import(/* webpackChunkName: "portalPlaylist" */ '../components/playlist'),
      beforeEnter: requireAuth,
      meta: {},
    },
    {
      path: '/forgot-password/:token',
      name: 'newforgotPassword',
      component: () => import(/* webpackChunkName: "portalForgotPassword" */ '../components/forgotPassword'),
      beforeEnter: requireNotAuth,
      meta: {},
    },
    {
      path: '/forgottenPassword',
      name: 'forgotPassword',
      component: () => import(/* webpackChunkName: "portalForgottenPassword" */ '../pages/forgot-password/index'),
      beforeEnter: requireNotAuth,
      meta: {},
    },
    {
      path: '/playlists',
      name: 'playlists',
      component: () => import(/* webpackChunkName: "portalPlaylist" */ '../pages/playlist/index'),
      beforeEnter: requireAuth,
      meta: {},
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "portalLogin" */ '../pages/login/index'),
      beforeEnter: requireNotAuth,
      meta: {},
    },
    {
      path: '/registration',
      name: 'registration',
      component: () => import(/* webpackChunkName: "portalRegistration" */ '../pages/registration/index'),
      beforeEnter: requireNotAuth,
      meta: {},
    },
    {
      path: '/registration/:token',
      name: 'registrationVerification',
      component: () => import(/* webpackChunkName: "portalVerification" */ '../pages/registration/_token'),
      beforeEnter: requireNotAuth,
      meta: {},
    },
    {
      path: '*',
      name: 'notfound',
      component: () => import(/* webpackChunkName: "portalNotFound" */ '@/pages/error/404'),
    },
  ],
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

let lastVisitedRoute = '/';
router.beforeEach((to, from, next) => {
  lastVisitedRoute = to.fullPath;
  document.title = (typeof to.meta.title !== 'undefined' ? to.meta.title + ' - Fitradio.hu' : 'Fitradio.hu');
  next();
});

router.onError(error => {
  if (error.message.match(new RegExp('loading chunk .* failed', 'i'))) {
    window.location.href = process.env.VUE_APP_API_BASE_URL.replace(/\/$/, '') + lastVisitedRoute;
  }
});

export default router;
