<template>
  <b-row class="tab-selector-cont">
    <b-col cols="12" lg="9">
      <b-row class="text-center uppercase">
        <b-col
          :cols="$store.state.auth.user.type==='GYM'? 12 : 6"
          :class="[
            'tab-selector',
            'pointer',
            $route.name === 'channels' || $route.name === 'channel'
              ? 'active'
              : '',
          ]"
          ><router-link :to="{ path: '/channels' }">
            <b> {{ $t("channels") }}</b>
          </router-link></b-col
        >
        <b-col v-if="$store.state.auth.user.type==='USER'"
          cols="6"
          :class="[
            'tab-selector',
            'pointer',
            $route.name === 'playlists' || $route.name === 'playlist'
              ? 'active'
              : '',
          ]"
          ><router-link :to="{ path: '/playlists' }">
            <b> {{ $t("playlists") }}</b>
          </router-link></b-col
        >
      </b-row>
    </b-col>
  </b-row>
</template>

<script type="text/babel">
export default {
  name: "tabselector",

  components: {},
  computed: {},
  watch: {
    router1: {
      handler(val) {
        //console.log("routername", val)
        //this.routerName = val.currentRoute.name;
      },
      deep: true,
    },
  },
  data() {
    return {
      routerName: "",
    };
  },

  methods: {},

  created() {},

  mounted() {},

  beforeDestroyed() {},
};
</script>

<style lang="scss" rel="stylesheet/scss">
@import "@/styles/colors.scss";
.tab-selector-cont {
  width: 100vw;
    top: 0;
    left: 25%;
    z-index: 100;
    margin: 0 !important;
    position: fixed;
  .tab-selector {
    background: #464646;
    a {
      color: white;
      font-size: 12px;
      width: 100%;
      display: flex;
      height: 100%;
      padding: 10px;
      justify-content: center;
      align-items: center;
      &:hover {
        color: white;
      }
    }
    &.active {
      background: $btnprimary !important;
      a {
        color: white;
        font-size: 16px;
      }
    }
  }
}
@media(max-width:991px){
  .tab-selector-cont {
    top:48px;
left:0;
  }
}
</style>
