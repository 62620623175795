var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"tab-selector-cont"},[_c('b-col',{attrs:{"cols":"12","lg":"9"}},[_c('b-row',{staticClass:"text-center uppercase"},[_c('b-col',{class:[
          'tab-selector',
          'pointer',
          _vm.$route.name === 'channels' || _vm.$route.name === 'channel'
            ? 'active'
            : '',
        ],attrs:{"cols":_vm.$store.state.auth.user.type==='GYM'? 12 : 6}},[_c('router-link',{attrs:{"to":{ path: '/channels' }}},[_c('b',[_vm._v(" "+_vm._s(_vm.$t("channels")))])])],1),(_vm.$store.state.auth.user.type==='USER')?_c('b-col',{class:[
          'tab-selector',
          'pointer',
          _vm.$route.name === 'playlists' || _vm.$route.name === 'playlist'
            ? 'active'
            : '',
        ],attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":{ path: '/playlists' }}},[_c('b',[_vm._v(" "+_vm._s(_vm.$t("playlists")))])])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }