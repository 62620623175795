var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('loader',{class:[!_vm.$store.getters['loader/show']? 'd-none' : 'd-flex' ]}),_c('b-container',{attrs:{"fluid":""}},[_c('langChanger'),(_vm.$route.name !== 'admin' && _vm.$route.name !== 'notfound')?_c('div',{staticClass:"row"},[(_vm.$store.getters['auth/isLoggedIn'])?_c('b-col',{class:[
            'player-wrapper',
            _vm.$route.name === 'admin' ? 'd-none' : '',
            _vm.playerShow ? 'player-show' : 'player-hidden',
          ],staticStyle:{"padding":"0"},attrs:{"cols":"12","lg":"3"},on:{"update:playershow":function($event){_vm.playerShow = $event}}},[_c('player')],1):_vm._e(),(_vm.$store.getters['auth/isLoggedIn'])?_c('b-col',{class:[
            _vm.$store.getters['auth/isLoggedIn'] && _vm.$route.name !== 'admin'
              ? ''
              : 'd-none',
            'content-wrapper',
          ],attrs:{"cols":"12","lg":"9"}},[_c('navigation',{staticClass:"d-block d-lg-none"}),(_vm.$route.name !== 'admin')?_c('tab-selector'):_vm._e(),(
              _vm.$router.currentRoute.name === 'channel' ||
              _vm.$router.currentRoute.name === 'playlist'
            )?_c('div',{staticClass:"back-icon pointer",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('svg',{staticStyle:{"enable-background":"new 0 0 384 384"},attrs:{"version":"1.1","id":"Capa_1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 384 384","xml:space":"preserve"}},[_c('g',[_c('polygon',{attrs:{"points":"384,170.667 81.707,170.667 158.187,94.187 128,64 0,192 128,320 158.187,289.813 81.707,213.333 384,213.333\n\t\t\t"}})])])]):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[_c('router-view',{staticStyle:{"min-height":"calc(100% - 44px)"}})],1)],1):_vm._e()],1):_vm._e(),(
          (_vm.$store.getters['auth/isLoggedIn'] && _vm.$route.name === 'admin') ||
          !_vm.$store.getters['auth/isLoggedIn']
        )?_c('b-row',[_c('transition',{attrs:{"name":"fade"}},[_c('router-view',{staticStyle:{"min-height":"100vh"}})],1)],1):_vm._e(),(_vm.$route.name === 'notfound' && _vm.$store.getters['auth/isLoggedIn'])?_c('b-row',[_c('transition',{attrs:{"name":"fade"}},[_c('router-view')],1)],1):_vm._e()],1),((_vm.$store.getters['auth/isLoggedIn'] && Object.keys(_vm.$store.state.auth.FBook).length<1)
      || (typeof _vm.$store.state.auth.FBook !== 'object') )?_c('v-facebook-login',{staticClass:"d-none",attrs:{"app-id":_vm.fbClientId},on:{"sdk-init":_vm.handleSdkInit}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }