export default {
    namespaced: true,

    state: {
        counter: 0,
    },
    mutations: {
        show(state) {
            state.counter++;
        },
        hide(state) {
            state.counter = state.counter > 0 ? state.counter - 1 : 0;
        },
        reset(state) {
            state.counter = 1;
        },
    },
    getters: {

        counter: state => state.counter,
        show: state => state.counter > 0,
    },
};
