import axios from 'axios';
const moment = require('moment');
export const versionMixin = {
    data() {
        return {
            $_version: null,
            $_versionLastChecked: null,
            $_isCheckingVersion: false,
        };
    },

    methods: {
        $_setVersionChecked() {
            this.$_versionLastChecked = moment();
            this.$_isCheckingVersion = false;
        },

        async $_checkVersion() {
            if (this.$_isCheckingVersion) {
                return;
            }

            if (this.$_versionLastChecked && moment().diff(this.$_versionLastChecked, 'seconds') < 60) {
                return;
            }

            this.$_isCheckingVersion = true;

            let result;
            try {
                result = await axios.get('/version', {
                    params: {
                        t: moment().format('x'),
                    },
                });
            } catch (error) {
                console.log(error);
                this.$_setVersionChecked();

                return;
            }

            if (!result || !result.data) {
                this.$_setVersionChecked();

                return;
            }

            result.data = result.data + '';
            if (result.data.length !== 40) {
                this.$_setVersionChecked();

                return;
            }

            if (this.$_version && this.$_version !== result.data) {
                this.$_setVersionChecked();
                location.reload();
                //window.location.reload(true);

                return;
            }

            this.$_version = result.data;
            this.$_setVersionChecked();
        },
    },

    created() {
        if (process.env.NODE_ENV !== 'dev') {
            this.$_checkVersion();

            window.onfocus = () => {
                this.$_checkVersion();
            };
        }
    },
};
