<template>
  <div class="c-navbar c-navbar-main">
    <b-navbar type="light" class="">
      <b-navbar-nav class="mr-auto">
        <b-navbar-brand href="#" class="d-block d-lg-none"
          ><b-img :src="require('@/assets/logo.svg')" style="width: 120px"
        /></b-navbar-brand>
        <b-nav-item-dropdown left class="relative" style="">
          <template v-slot:button-content>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-person-circle"
              viewBox="0 0 16 16"
            >
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
              <path
                fill-rule="evenodd"
                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
              />
            </svg>
          </template>
          <b-dropdown-header
            disabled
            class="white--text user-btn mb-2"
            style=""
            >{{ user.email }}</b-dropdown-header
          >
          <b-dropdown-divider></b-dropdown-divider>

          <b-dropdown-item
            href="#"
            @click="logoutButton()"
            class="lighter--text"
            >{{ $t("signout") }}</b-dropdown-item
          >
          <b-dropdown-item href="#" @click="contact()" class="lighter--text">{{
            $t("contact")
          }}</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-group id="" :header="$t('language')">
            <b-dropdown-item-button
              :class="[$i18n.locale === item.id ? 'active' : '']"
              v-for="(item, i) in langs"
              :key="i"
              @click="changeLang(item.id)"
              >{{ item.text }}</b-dropdown-item-button
            >
          </b-dropdown-group>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
    <div class="text-center mt-3 mb-3 d-none d-lg-block">
      <b-img :src="require('@/assets/logo.svg')" style="width: 40%" />
    </div>
  </div>
</template>

<script type="text/babel">
import Vue from "vue";
//import TabSelector from "@/components/tabSelector";
export default Vue.extend({
  data() {
    return {
      langs: [
        { id: "hu", text: "Magyar" },
        { id: "en", text: "English" },
      ],
    };
  },

  methods: {
    logoutButton() {
      let this_=this;
      this.$store.commit('loader/show');
      this.$store.commit("channels/setChannel", {});
      this.$store.commit("channels/setPlayChannel", false);
      if (this.$store.state.auth.FBook.hasOwnProperty("api")) {
        this.$store.state.auth.FBook.getLoginStatus(function (response) {
          if (response && response.status === "connected") {
            this_.$store.state.auth.FBook.api(
              "/me/permissions",
              "delete",
              null,
              () =>
                FB.logout(function (response) {
                  this_.$store.dispatch("auth/onLogout");
                })
            );
          }else{
             this_.$store.dispatch("auth/onLogout");
          }
        });
      } else {
        this.$store.dispatch("auth/onLogout").then(() => {
          this._debug('logout')
          // this.$router.push({ name: "login" });
        });
      }
    },
    contact() {
      window.open("https://fitradio.hu");
    },
    changeLang(lang) {
      this.$i18n.locale = lang;
    },
  },
  components: {
    //TabSelector
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },

    user() {
      return this.$store.getters["auth/user"];
    },
    google() {
      return this.$store.state.auth.gAuth2;
    },
    fb() {
      return this.$store.state.auth.FBook;
    },
  },
});
</script>

<style lang="scss" rel="stylesheet/scss">
@import "@/styles/colors.scss";
.c-navbar {
  background: transparent;
  top: 0;
  width: 100%;
  z-index: 1000;
  svg {
    width: 25px;
    height: 25px;
    * {
      fill: white;
    }
  }
  .navbar {
    padding: 0.2rem 0.5rem;
    .navbar-brand {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .lighter--text .dropdown-item {
      color: #f3f3f3;
    }
  }
  .dropdown-toggle {
    &:after {
      border-top: 0.3em solid white !important;
    }
  }
}
@media (max-width: 991px) {
  .c-navbar {
    background: #757575;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    position: fixed;
    .navbar-nav {
      flex-direction: row;
      .dropdown-menu {
        position: absolute;
      }
    }
    svg {
      width: 20px;
    }
  }
}
</style>

