export default {
    namespaced: true,

    state: {
        playerShow:true
    },
    mutations: {
        setShow(state, val) {
            state.playerShow = val;
        },
    
    },
    actions: {

        setShow({ commit }, payload) {
          commit('setShow', payload);
        },
      
    },
    getters: {
        getPlayerShow: state => state.playerShow,
   
    },
};
