<template>
    <div class="lds-ring-wrapper"><div class="lds-ring"></div><div class="lds-ring"></div><div class="lds-ring"></div><div class="lds-ring"></div></div>
</template>

<script type="text/babel">
    export default {
        name: 'loader',
    }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>

</style>
