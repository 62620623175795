import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import hu from 'vee-validate/dist/locale/hu.json';

// Install English and Arabic locales.
localize({
  en,
  hu
});
localize(localStorage.getItem('locale') || 'hu');

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  // const locales = require.context('@/locales', true, /en\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export default new VueI18n({
  locale: localStorage.getItem('locale') || 'hu',
  fallbackLocale: 'hu',
  silentFallbackWarn: true,
  messages: loadLocaleMessages()
});
