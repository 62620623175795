
<template>
  <div class="lang-changer">
    <b-dropdown id="dropdown-lang bg-toggle" class=" m-md-2 ml-md-4" text="">
        <template #button-content>
      <svg version="1.1" class="flag-icon" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g transform="translate(1 1)">
	
		<path d="M365.955,191l77.444-116.166c9.451-14.177-0.712-33.167-17.75-33.167h-320V20.333C105.649,8.551,96.097-1,84.315-1
			C72.533-1,62.982,8.551,62.982,20.333V63v256v170.667c0,11.782,9.551,21.333,21.333,21.333c11.782,0,21.333-9.551,21.333-21.333
			V340.333h320c17.039,0,27.202-18.99,17.75-33.167L365.955,191z M105.649,297.667V84.333h280.138l-63.222,94.833
			c-4.777,7.166-4.777,16.501,0,23.667l63.222,94.833H105.649z"/>
	</g>
    </svg><span class="sr-only">Lang</span>
    </template>
    <b-dropdown-header id="">
      {{$t('language')}}
    </b-dropdown-header>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item :class="[$i18n.locale===item.id? 'active' :'']" v-for="(item,i) in langs" :key="i" @click="changeLang(item.id)">{{item.text}}</b-dropdown-item>
    
    </b-dropdown>
  </div>
</template>

<script type="text/babel">
import Vue from "vue";
//import TabSelector from "@/components/tabSelector";
export default Vue.extend({
  data() {
    return {
        langs: [{id:'hu',text:'Magyar'}, {id:'en', text:'English'}]
    };
  },

  methods: {
   changeLang(lang){
       this.$i18n.locale = lang
   }
  },
  components: {
    //TabSelector
  },
  computed: {
   
  },
});
</script>

<style lang="scss" rel="stylesheet/scss">
@import "@/styles/colors.scss";
.lang-changer{
    position: fixed;
    right: 0;
    z-index: 100;
    .dropdown-toggle{
        background: transparent;
        border: 0;
.flag-icon{
    width:25px;
    fill:white
}
    }

}


</style>

