import { setToken, removeTokens } from '@/util/auth';
import { apiClient } from '@/util/resources';
import router from '@/router/routes';
export default {
  namespaced: true,

  state: {
    user: {},
    token: null,
    refreshToken: null,
    loginPersonal: true,
    FBook: {},
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setToken(state, value) {
      state.token = value;
    },
    setRefreshToken(state, value) {
      state.refreshToken = value;
    },
    setLoginPersonal(state, value) {
      state.loginPersonal = value;
    },
    setFB(state, value) {
      state.FBook = value;
    },
    setGoogle(state, value) {
      state.gAuth2 = value;
    }
  },
  actions: {
    async onLogin({ commit, dispatch }, payload) {
      setToken(payload);

      dispatch('setTokens', payload);
      await dispatch('fetchUser');
    },

    async onLogout({ commit }) {
      removeTokens();
      commit('setToken', null);
      // commit('setRefreshToken', null);
      commit('setUser', {});
      // router.push({ name: 'home' });
    },


    setTokens({ commit }, payload) {
      commit('setToken', payload.access_token);
      // commit('setRefreshToken', payload.refresh_token);
    },
    setLoginPersonal({ commit }, payload) {
      commit('setLoginPersonal', payload);
      // commit('setRefreshToken', payload.refresh_token);
    },
    setFB({ commit }, payload) {
      commit('setFB', payload);
    },
    setGoogle({ commit }, payload) {
      commit('setGoogle', payload);
    },
    async fetchUser({ commit, dispatch }) {
      await apiClient.get('/api/me').then((response) => {
        commit('setUser', response.data);
        dispatch('mercure/subscribeUser', response.data, { root: true });
      });
    }
  },
  getters: {
    isLoggedIn: state => state.user.hasOwnProperty('email'),
    user: state => state.user,
    id: state => state.user.id,
    token: state => state.token,
    refreshToken: state => state.refreshToken,
    oldToken: () => {
      const sessionData = localStorage.getItem('sessionData');
      if (sessionData && JSON.parse(sessionData)['sessionId']) {
        return JSON.parse(sessionData)['sessionId'];
      }
      return null;
    },
  },
};
