<template>
  <div id="app">
    <loader
      :class="[!$store.getters['loader/show']? 'd-none' : 'd-flex' ]"
    ></loader>

    <b-container fluid>
      <langChanger></langChanger>
      <div
        class="row"
        v-if="$route.name !== 'admin' && $route.name !== 'notfound'"
      >
        <b-col
          cols="12"
          lg="3"
          :class="[
            'player-wrapper',
            $route.name === 'admin' ? 'd-none' : '',
            playerShow ? 'player-show' : 'player-hidden',
          ]"
          style="padding: 0"
          v-if="$store.getters['auth/isLoggedIn']"
          v-on:update:playershow="playerShow = $event"
        >
          <player />
        </b-col>
        <b-col
          cols="12"
          lg="9"
          :class="[
            $store.getters['auth/isLoggedIn'] && $route.name !== 'admin'
              ? ''
              : 'd-none',
            'content-wrapper',
          ]"
          style=""
          v-if="$store.getters['auth/isLoggedIn']"
        >
          <navigation class="d-block d-lg-none" />
          <tab-selector v-if="$route.name !== 'admin'" />

          <div
            @click="$router.go(-1)"
            class="back-icon pointer"
            v-if="
              $router.currentRoute.name === 'channel' ||
              $router.currentRoute.name === 'playlist'
            "
          >
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 384 384"
              style="enable-background: new 0 0 384 384"
              xml:space="preserve"
            >
              <g>
                <polygon
                  points="384,170.667 81.707,170.667 158.187,94.187 128,64 0,192 128,320 158.187,289.813 81.707,213.333 384,213.333
			"
                />
              </g>
            </svg>
          </div>
          <transition name="fade">
            <router-view style="min-height: calc(100% - 44px)"></router-view>
          </transition>
        </b-col>
      </div>
      <b-row
        v-if="
          ($store.getters['auth/isLoggedIn'] && $route.name === 'admin') ||
          !$store.getters['auth/isLoggedIn']
        "
      >
        <transition name="fade">
          <router-view style="min-height: 100vh"></router-view>
        </transition>
      </b-row>
      <b-row
        v-if="$route.name === 'notfound' && $store.getters['auth/isLoggedIn']"
      >
        <transition name="fade">
          <router-view></router-view>
        </transition>
      </b-row>
    </b-container>
     <v-facebook-login
      v-if="($store.getters['auth/isLoggedIn'] && Object.keys($store.state.auth.FBook).length<1)
      || (typeof $store.state.auth.FBook !== 'object') "
      :app-id="fbClientId"
      @sdk-init="handleSdkInit"
      class="d-none">
    </v-facebook-login>
  </div>
</template>

<script type="text/babel">
import Loader from "./partials/Loader";
import DefaultLayout from "./layouts/DefaultLayout";
import Player from "./components/player";
import TabSelector from "@/components/tabSelector";
import LangChanger from "@/components/langChanger";
import Navigation from "@/partials/Navigation";
import {versionMixin} from "@/util/mixin-version";
export default {
  name: "app",

  components: {
    DefaultLayout,
    // Navigation,
    // Footer,
    TabSelector,
    Loader,
    Player,
    Navigation,
    LangChanger,
  },
  mixins:[versionMixin],
  data() {
    return {
      ready: true,
      sending: false,
      isLoading: false,
      layout: DefaultLayout,
      fbClientId: process.env.VUE_APP_FB_CLIENT_ID,
      gapiInterval: "",
      googleClientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    };
  },

  computed: {
    playerShow: {
      get() {
        return this.$store.state.player.playerShow;
      },
      set(value) {
        this.$store.commit("player/setShow", value);
      },
    },
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    loader(){
      return this.$store.getters['loader/show']
    },
    router(){
      return this.$router
    }

  },
  watch: {
    isLoggedIn(nv, ov) {
      if (!nv) {
        this.$router.push("/");
        this.$store.commit('loader/hide');
      }
    },
  },
  methods: {
    updatePlayerShow(e) {
      this._debug("e", e);
    },
    //social inits, because the login page not always show
    handleSdkInit({ FB, scope }) {
      // this.FB = FB;
      this.$store.dispatch("auth/setFB", FB);
    },
    checkGapi() {
      let this_ = this;
      if (
        typeof this.$store.state.auth.gAuth2 !== "object" ||
        Object.keys(this.$store.state.auth.gAuth2).length < 1
      ) {
        this.gapiInterval = setInterval(function () {
          if (window.gapi || gapi) {
            clearInterval(this_.gapiInterval);
            this_.initGApi();
          }
        }, 1000); // xx * 1000 milsec
      }
    },
    initGApi() {
      let this_ = this;
      window.gapi.load("auth2", () => {
        this.$store.dispatch(
          "auth/setGoogle",
          window.gapi.auth2.init({
            client_id: this.googleClientId,
            //cookiepolicy: "single_host_origin",
          })
        );
      });
    },
  },

  created() {
  },

  mounted() {
    this._debug("mounted app test");
    this.$store.commit('loader/hide');
   // this.checkGapi();

  },

  beforeDestroyed() {
    $("body").css("overflowY", "auto");
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
body,
html {
  height: auto !important;
}
.back-icon {
  position: absolute;
  z-index: 10;
  left: 10px;
  top: 10px;
  svg {
    width: 1.7vw;
    fill: #427387;
  }
}
@media (max-width: 991px) {
  .player-wrapper {
    z-index: 1 !important;
  }
  .player-show {
    bottom: 0;
    padding-top: 89px !important;
    .player-circle {
      .progress-cont {
        width: 50vw;

        position: absolute;
        left: 16vw;
        bottom: 20vw;
        .progress__time {
          font-size: 3vw;
        }
      }
    }
  }
  .player-hidden {
    height: 100px !important;
    overflow: hidden;
    position: fixed !important;
    width: 100% !important;
    bottom: 0;
    z-index: 1;
    .down-icon {
      position: absolute;
      top: 15px;
    }
    .player-bg {
      background: #464646 !important;
      .player-circle {
        position: fixed;
        bottom: 50px;
        left: 60px;
        width: 200px;
        height: 50px;
        &:before {
          border: 0;
          background: transparent;
        }

        .prev-icon,
        .next-icon {
          width: 20px;
          height: 20px;
          top: 20px;
        }
        .player-play {
          width: 25px;
          height: 25px;
          margin-top: 0;
          left: 45px;
          top: 23px;
          &:before {
            width: 40px;
            height: 40px;
          }
          .play-icon {
            border-top: 13px solid transparent;
            border-left: 18px solid red;
            border-bottom: 13px solid transparent;
          }
          .pause-icon {
            &:before {
              width: 3px;
              left: 8px;
            }
            &:after {
              width: 3px;
            }
          }
        }
        .prev-icon {
          left: 0;
        }
        .next-icon {
          left: 95px;
        }
        .player-title {
          display: none;
        }
        .player-song.large-song {
          display: none;
        }
        .progress-cont {
          bottom: 38px;
          left: 130px;
          width: calc(100vw - 210px);
          position: relative;
          .progress__time {
            font-size: 15px;
            top: 4px;
            margin: 0;
            position: relative;
            > span:first-of-type {
              position: relative;
              width: 50px;
              left: 0;
            }
            span:last-of-type {
              position: relative;
              left: 0;
            }
          }
        }
      }
    }
    .inactive {
      .play-icon {
        border-left: 20px solid #afafaf !important;
      }
    }
  }
  .player-show,
  .player-hidden {
    transition: 0.3s all linear;
  }
}
</style>
