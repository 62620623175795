import router from '@/router/routes';
import { apiClient } from '@/util/resources';
import store from '@/store/store';
import i18n from '@/locales/i18n';

export function getAuthToken() {
  if (localStorage.getItem('_access_token')) {
    return localStorage.getItem('_access_token');
  }
  const sessionData = localStorage.getItem('sessionData');
  if (sessionData && JSON.parse(sessionData)['sessionId']) {
    return JSON.parse(sessionData)['sessionId'];
  }
}

export function forgottenPassword(context, creds) {
  apiClient.post('api/forgotpwd?_locale=' + i18n.locale, creds).then(response => {
    context.$root.$bvToast.toast(i18n.t('_auth.forgotten_password_email_sent'), {
      title: i18n.t('_success'),
      variant: 'success',
      solid: true,
      toaster: 'b-toaster-top-center',
    });

    router.push({ name: 'login' });
  }).catch(error => {
    context.$root.$bvToast.toast(error.message, {
      title: i18n.t('_error'),
      variant: 'danger',
      solid: true,
      noAutoHide: true,
      toaster: 'b-toaster-top-center',
    });

    context.forgottenPasswordSubmitted = false;
  });
}

export async function profileNewPassword(context, creds) {
  await apiClient.post('api/profile/newpassword', creds).then(response => {
    context.$root.$bvToast.toast(i18n.t('_auth.new_password_successful'), {
      title: i18n.t('_success'),
      variant: 'success',
      solid: true,
      toaster: 'b-toaster-top-center',
    });

    router.push({ name: 'home' });
  }).catch(error => {
    context.$root.$bvToast.toast(error.message, {
      title: i18n.t('_error'),
      variant: 'danger',
      solid: true,
      noAutoHide: true,
      toaster: 'b-toaster-top-center',
    });
  });
}

export async function profileNewEmail(context, creds) {
  await apiClient.post('api/profile/newemail', creds).then(response => {
    context.$root.$bvToast.toast(i18n.t('_auth.new_email_successful'), {
      title: i18n.t('_success'),
      variant: 'success',
      solid: true,
      toaster: 'b-toaster-top-center',
    });

    router.push({ name: 'home' });
  }).catch(error => {
    context.$root.$bvToast.toast(error.message, {
      title: i18n.t('_error'),
      variant: 'danger',
      solid: true,
      noAutoHide: true,
      toaster: 'b-toaster-top-center',
    });
  });
}

export async function newPassword(context, creds) {
  await apiClient.post('api/forgotpwd/newpassword?_locale=' + i18n.locale, creds).then(response => {
    context.$root.$bvToast.toast(i18n.t('_auth.new_password_successful'), {
      title: i18n.t('_success'),
      variant: 'success',
      solid: true,
      toaster: 'b-toaster-top-center',
    });

    router.push({ name: 'login' });
  }).catch(error => {
    context.$root.$bvToast.toast(error.message, {
      title: i18n.t('_error'),
      variant: 'danger',
      solid: true,
      noAutoHide: true,
      toaster: 'b-toaster-top-center',
    });
  });
}

export function logout({ context = null, token = null } = {}) {
  let params = {};
  // if (getAuthRefreshToken()) {
  //     params = { refresh_token: getAuthRefreshToken() };
  // }
  apiClient.post('api/logout', params).then(response => {
    removeTokens();
    store.dispatch('auth/onLogout');

  }).catch(error => {
    removeTokens();
    store.dispatch('auth/onLogout');
    router.push({ name: 'home' });
  });
}

export function silentLogout() {
  removeTokens();
  store.dispatch('auth/onLogout');
  router.push({ name: 'home', query: { redirect: router.currentRoute.fullPath } });
}

export async function requireNotAuth(to, from, next) {
  if (store.getters['auth/isLoggedIn']) {
    next({
      name: 'channels',
    });
  } else {
    next();
  }
}

export async function requireAuth(to, from, next) {
  if (!store.getters['auth/isLoggedIn'] && getAuthToken()) {
    await store.dispatch('auth/onLogin', {
      access_token: getAuthToken(),
    });
  }

  if (store.getters['auth/isLoggedIn']) {
    next();
  } else {
    
    next({
      path: '',
      query: { redirect: to.fullPath },
    });
  }
}

// function can(permission) {
//     return !_.isUndefined(getUserData()[permission]) && !_.isNull(getUserData()[permission]);
// }

export function setToken(data) {
  localStorage.setItem('_access_token', data.access_token);
  // localStorage.setItem('portal_refresh_token', data.refresh_token);
}

export function removeTokens() {
  localStorage.removeItem('_access_token');
  localStorage.removeItem('sessionData');
  // localStorage.removeItem('portal_refresh_token');
}
