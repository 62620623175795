export default {
    namespaced: true,

    state: {
        playlists:[],
        playlist:'',
        playPlaylist:false,
        playlistIndex:0
    },
    mutations: {
        setPlaylists(state, playlists) {
            state.playlists = playlists;
        },
        setPlaylist(state, playlist) {
            state.playlist = playlist;
        },
        setPlayPlaylist(state, playPlaylist) {
            state.playPlaylist = playPlaylist;
        },
        setPlaylistIndex(state, index) {
            state.playlistIndex = index;
        },
    },
    actions: {

        setPlaylists({ commit }, payload) {
          commit('setPlaylists', payload);
        },
        setPlaylist({ commit }, payload) {
            commit('setPlaylist', payload);
          },
          setPlayPlaylist({ commit }, payload) {
            commit('playPlaylist', payload);
          },
          setPlaylistIndex({ commit }, payload) {
            commit('playlistIndex', payload);
          },
    },
    getters: {
        getPlaylists: state => state.playlists,
        getPlaylist: state => state.playlist,
        getPlayPlaylist: state => state.playPlaylist,
        getPlaylistIndex: state => state.playlistIndex,
    },
};
