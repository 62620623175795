import { apiClient, silentApiClient, fileUploadClient } from '@/util/resources';
import i18n from '@/locales/i18n';
import store from '@/store/store';
import { silentLogout } from '../util/auth';

// Request interceptor
apiClient.interceptors.request.use((config) => {
  return requestOnFulfilled(config);
}, (error) => {
  return requestOnRejected(error);
});
// Response interceptor
apiClient.interceptors.response.use((response) => {
  return responseOnFulfilled(response);
}, (error) => {
  return responseOnRejected(error);
});

// Request interceptor
silentApiClient.interceptors.request.use((config) => {
  return requestOnFulfilled(config);
}, (error) => {
  return requestOnRejected(error);
});
// Response interceptor
silentApiClient.interceptors.response.use((response) => {
  return responseOnFulfilled(response);
}, (error) => {
  return responseOnRejected(error);
});

// Request interceptor
fileUploadClient.interceptors.request.use((config) => {
  return requestOnFulfilled(config);
}, (error) => {
  return requestOnRejected(error);
});
// Response interceptor
fileUploadClient.interceptors.response.use((response) => {
  return responseOnFulfilled(response);
}, (error) => {
  return responseOnRejected(error);
});

function requestOnRejected(error) {
  // if (!silent) {
  //     setLoading(false);
  // }
  console.log('RequestError: ', error.response);
  // Do something with request error
  return Promise.reject(error);
}

function requestOnFulfilled(config) {
  if (store.getters['auth/token']) {
    config.headers.Authorization = `Bearer ${store.getters['auth/token']}`;
  }

  return config;
}

function responseOnFulfilled(response) {
  return response;
}

function responseOnRejected(error) {
  console.log('error: ', error);
  console.log('ResponseError: ', error.response);

  if (!error.response) {
    error.response = {
      status: 500,
      statusText: 'Unknown error.',
    };
  }

  // Return any error which is not due to authentication back to the calling service
  if (error.response.status !== 401) {
    // Default error
    let errorObj = {
      httpCode: error.response.status,
      errorCode: null,
      message: i18n.t('_errors.default')
    };

    // errorCode IN [500, 404]
    if ([500, 404].includes(error.response.status) && typeof error.response.data === 'string') {
      if (i18n.t('_errors.' + error.response.status)) {
        errorObj.message = i18n.t('_errors.' + error.response.status);
      } else {
        errorObj.message = error.response.statusText;
      }
    } else if (typeof error.response.data === 'object') {
      errorObj.errorCode = error.response.data.code;

      /* eslint-disable no-lonely-if */
      if (i18n.t('_errors.' + error.response.data.code)) {
        errorObj.message = i18n.t('_errors.' + error.response.data.code);
      } else {
        errorObj.message = 'Error code: ' + error.response.data.code;
      }

      if (error.response.data.violations) {
        errorObj.violations = error.response.data.violations;
      }
    }

    return Promise.reject(errorObj);
  }

  // Logout user if token refresh didn't work or user is disabled
  if (error.response.status === 401) {
    silentLogout();
  }

  // if (store.getters['auth/refreshToken']) {
  //   return apiClient.post('api/token/refresh', { refresh_token: store.getters['auth/refreshToken'] })
  //     .then(response => {
  //
  //       store.dispatch('auth/onLogin', response.data);
  //
  //       // New request with new token
  //       const config = error.config;
  //       config.headers.Authorization = `Bearer ${response.data.token}`;
  //
  //       return new Promise((resolve, reject) => {
  //         apiClient.request(config).then(response2 => {
  //           resolve(response2);
  //         }).catch((error2) => {
  //           reject(error2);
  //         });
  //       });
  //     }).catch(error2 => {
  //       Promise.reject(error2);
  //     });
  // }

  // Default error
  let errorObj = {
    httpCode: error.response.status,
    errorCode: null,
    message: i18n.t('_errors.' + error.response.status),
  };

  return Promise.reject(errorObj);
}
