<template>
  <div>
    <Navigation v-if="$store.getters['nav/show']"/>
    <loader :class="{'loader-hidden': !$store.getters['loader/show']}"></loader>
    <slot></slot>
    <Footer/>
  </div>
</template>

<script>
import Navigation from './../partials/Navigation';
import Footer from './../partials/Footer';
import Loader from './../partials/Loader';

export default {
  name: 'DefaultLayout',
  components: {
    Navigation,
    Footer,
    Loader
  }
};
</script>

<style scoped>

</style>
