import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import loader from './modules/loader';
import auth from './modules/auth';
import mercure from './modules/mercure';
import channels from './modules/channels';
import playlists from './modules/playlists';
import player from './modules/player';

Vue.use(Vuex);

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    modules: {
        loader,
        auth,
        mercure,
        channels,
        playlists,
        player
    },
});

export default store;
