import axios from 'axios';

export const PAGINATION_OPTIONS = {
    TOTAL_ROWS: 1,
    RESULT: 2,
    ALL: 3,
};

// Resources for /posts endpoint on API
// @see https://github.com/mzabriskie/axios#creating-an-instance
export const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
});

export const silentApiClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
});

export const fileUploadClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});
